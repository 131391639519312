// Global packages and functions
import { useEffect } from 'react'
import { useLocation } from '@remix-run/react'
import {
  newTracker,
  trackPageView,
  disableAnonymousTracking,
  enableAnonymousTracking,
} from '@snowplow/browser-tracker'
import {
  FormTrackingPlugin,
  enableFormTracking,
} from '@snowplow/browser-plugin-form-tracking'
import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking,
} from '@snowplow/browser-plugin-button-click-tracking'
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking'
import { SnowplowEcommercePlugin } from '@snowplow/browser-plugin-snowplow-ecommerce'

// Types
interface SnowplowProps {
  initUrl: string
  initAppID?: string
}

// Runs the functions that expose the disable and enable tracking functions
// to the window object
const exposeWindowData = () => {
  windowSnowplowDisable()
  windowSnowplowEnable()
}

// This function disables anonymous tracking
// When the user has given consent for tracking
// It is called via the GTM tag -> Override datalayer.
const windowSnowplowDisable = () => {
  if (typeof window !== 'undefined') {
    window.snowplow_disable_anonymous_tracking = () => {
      disableAnonymousTracking()
    }
  }
}

// This function enables anonymous tracking
// When the user has removed consent for tracking
// It is called via the GTM tag -> Override datalayer.
const windowSnowplowEnable = () => {
  if (typeof window !== 'undefined') {
    window.snowplow_enable_anonymous_tracking = () => {
      enableAnonymousTracking()
    }
  }
}

// Main export
const useSnowplow = ({
  initUrl,
  initAppID = 'hss-ecom-staging',
}: SnowplowProps) => {
  // Router navigation
  const location = useLocation()

  // Initialise tracker
  const tracker = initUrl
    ? newTracker('sp', initUrl, {
        appId: initAppID,
        discoverRootDomain: true,
        cookieSameSite: 'Lax',
        eventMethod: 'post',
        contexts: {
          webPage: true, // Default
          session: true, // Adds client session context entity to events, off by default. Available in v3.5+.
          browser: false, // Adds browser context entity to events, off by default. Available in v3.9+.
        },
        plugins: [
          FormTrackingPlugin(),
          ButtonClickTrackingPlugin(),
          LinkClickTrackingPlugin(),
          SnowplowEcommercePlugin(),
        ],
        anonymousTracking: {
          withServerAnonymisation: false,
          withSessionTracking: true,
        },
      })
    : null

  // Automatic page tracking on router change
  useEffect(() => {
    enableFormTracking()
    enableButtonClickTracking()
    enableLinkClickTracking()
    trackPageView()
  }, [location.pathname])

  // Expose functions to the window object
  useEffect(() => {
    exposeWindowData()
  }, [tracker])
}

export default useSnowplow
