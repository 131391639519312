/**
 * Create custom scripts and write them to the page. This is done in a way that it
 * doesn't break the hot reloading in React (because of GDPR we need to be able to
 * add and remove scripts on the fly).
 */

import { NonceType } from '~/types'

export const createGtmScript = (trackingId: string, nonce: NonceType) => {
  if (!trackingId) return null

  const script = document.createElement('script')

  script.setAttribute('id', 'tracking-gtm-insert')

  if (nonce) {
    script.setAttribute('nonce', nonce)
  }

  // @NOTE: For if we fully implement GTM environment containers, append to gtm.js
  // const gtmEnvSettings =
  //   env == 'production'
  //     ? '&gtm_auth=8RWLjO5zbwzmTVdwvmu3tg&gtm_preview=env-1&gtm_cookies_win=x'
  //     : '&gtm_auth=Xth88rHVkxY3-uZgR-dv-g&gtm_preview=env-11&gtm_cookies_win=x'

  script.innerHTML = `
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.setAttribute('id', 'tracking-gtm');
      j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${trackingId}');`

  return script
}

export const createGtmNoScript = (trackingId: string, nonce: NonceType) => {
  if (!trackingId) return null

  const script = document.createElement('noscript')

  script.setAttribute('id', 'tracking-gtm-noscript')

  if (nonce) {
    script.setAttribute('nonce', nonce)
  }

  script.innerHTML = `
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=${trackingId}"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
  `

  return script
}

export const TrustpilotScript = (nonce: NonceType) => {
  const script = document.createElement('script')

  if (nonce) {
    script.setAttribute('nonce', nonce)
  }

  script.setAttribute('id', 'trustpilot-script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute(
    'src',
    '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  )
  script.setAttribute('async', '')

  return script
}
