// Global packages and components
import { useRouteLoaderData, Link } from '@remix-run/react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import {
  UserIcon,
  ChevronDownIcon,
  ArrowDownOnSquareIcon,
} from '@heroicons/react/24/outline'

// Types
import type { RootDataProps } from '~/types'

const AccountPopover = () => {
  const { user } = useRouteLoaderData('root') as RootDataProps

  return (
    <Popover className="group relative">
      <PopoverButton className="flex items-center rounded-3xl bg-gray-100 px-4 py-2.5 outline-none">
        <UserIcon className="mr-2 size-5" />
        <span className="mr-2">Account</span>
        <ChevronDownIcon className="size-4 transition-transform group-data-[open]:rotate-180" />
      </PopoverButton>
      <PopoverPanel
        transition
        anchor="bottom"
        className="z-40 mt-2 flex flex-col rounded-lg bg-white p-5 shadow-md transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {user?.first_name && <h4>Hi, {user.first_name}!</h4>}
        <ul>
          {!user?.first_name ? (
            <>
              <li className="mb-1.5">
                <PopoverButton
                  as={Link}
                  to="/login"
                  className="flex min-w-52 rounded-3xl bg-gray-100 px-4 py-2"
                >
                  <UserIcon className="mr-2 size-5" />
                  Login
                </PopoverButton>
              </li>
              <li className="mb-1.5">
                <PopoverButton
                  as={Link}
                  to="/register"
                  className="flex min-w-52 rounded-3xl bg-gray-100 px-4 py-2"
                >
                  <UserIcon className="mr-2 size-5" />
                  Register
                </PopoverButton>
              </li>
            </>
          ) : (
            <>
              <li className="mb-1.5">
                <PopoverButton
                  as={Link}
                  to="/account"
                  className="flex min-w-52 rounded-3xl bg-gray-100 px-4 py-2"
                >
                  <UserIcon className="mr-2 size-5" />
                  My Account
                </PopoverButton>
              </li>
              <li className="mb-1.5">
                <PopoverButton
                  as={Link}
                  to="/account/logout"
                  className="flex min-w-52 rounded-3xl bg-gray-100 px-4 py-2"
                >
                  <ArrowDownOnSquareIcon className="mr-2 size-5" />
                  Log out
                </PopoverButton>
              </li>
            </>
          )}
        </ul>
      </PopoverPanel>
    </Popover>
  )
}

export default AccountPopover
