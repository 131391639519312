// Global packages and components
import Marquee from 'react-fast-marquee'
import { ClientOnly } from 'remix-utils/client-only'

// Types
import type { ReactNode } from 'react'

interface SiteMessageProps {
  children?: ReactNode
}

// Main export
const SiteMessage = ({ children }: SiteMessageProps) => (
  <div className="bg-emerald-green p-2 text-center text-xs text-white">
    <ClientOnly fallback={children}>
      {() => <Marquee autoFill>{children}</Marquee>}
    </ClientOnly>
  </div>
)

export default SiteMessage
