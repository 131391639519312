// Global packages and components
import { clsx } from 'clsx'
import { useState } from 'react'
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { NavLink } from '~/components'

// Types
import type { SanityNavItem } from '~/types'

interface Props {
  open: boolean
  items: SanityNavItem[]
  setOpen: (e: boolean) => void
}

// Main export
const OffcanvasNav = ({ open, items, setOpen }: Props) => {
  const [activeItems, setActiveItems] = useState<{
    grandParent: string | null
    parent: string | null
  }>({
    grandParent: null,
    parent: null,
  })

  // Close all navigation elements
  const closeAll = () => {
    setOpen(false)

    const timeout = setTimeout(() => {
      setActiveItems({
        grandParent: null,
        parent: null,
      })
    }, 700)

    return () => {
      clearTimeout(timeout)
    }
  }

  // Shared classes for the inner items
  const classOverlay =
    'absolute right-0 top-0 h-full w-[100vw] overflow-hidden bg-white transition-transform duration-500'
  const classList = 'h-full w-full overflow-y-auto py-2'
  const classListItem = 'block w-full px-8 py-4'

  // @NOTE: z-index of cookiebot sticky button is -> 2147483631
  return (
    <div
      className={clsx(
        'fixed left-0 top-0 z-[2147483632] flex h-[100vh] w-[100vw] flex-col overflow-hidden bg-white shadow-md transition-transform duration-500 lg:hidden',
        {
          'translate-y-0': open,
          'translate-y-full': !open,
        }
      )}
    >
      <div className="h-[80px] w-full shrink-0 border-b border-teal-green bg-neutral-100">
        <div className="flex h-full items-end items-center justify-between gap-2 px-5">
          <div>
            {activeItems.parent ? (
              <button
                onClick={() =>
                  setActiveItems(prevState => {
                    return {
                      ...prevState,
                      parent: null,
                    }
                  })
                }
                className="flex items-center text-teal-green"
              >
                <ChevronLeftIcon className="mr-2 size-5 shrink-0" />
                <span className="pt-0.5 text-left font-bold underline">
                  {activeItems.parent}
                </span>
              </button>
            ) : activeItems.grandParent ? (
              <button
                onClick={() =>
                  setActiveItems({
                    grandParent: null,
                    parent: null,
                  })
                }
                className="flex items-center text-teal-green"
              >
                <ChevronLeftIcon className="mr-2 size-5 shrink-0" />
                <span className="pt-0.5 text-left font-bold underline">
                  {activeItems.grandParent}
                </span>
              </button>
            ) : (
              <p className="mb-0 font-bold text-teal-green">Browse</p>
            )}
          </div>
          <div>
            <button
              className="flex size-8 items-center justify-center rounded-full bg-gray-200"
              onClick={closeAll}
            >
              <XMarkIcon className="size-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full flex-1 bg-white">
        {items && (
          <ul className={classList}>
            {items.map((grandParent, gi) => (
              <li key={'grandparent' + gi}>
                <NavLink
                  {...grandParent}
                  hasArrow
                  onClick={() =>
                    grandParent?.childItems
                      ? setActiveItems(prevState => {
                          return {
                            ...prevState,
                            grandParent: grandParent.title,
                          }
                        })
                      : undefined
                  }
                  className={classListItem}
                />
                {grandParent?.childItems && (
                  <div
                    className={clsx(classOverlay, {
                      'translate-x-0':
                        activeItems.grandParent == grandParent.title,
                      'translate-x-full':
                        activeItems.grandParent !== grandParent.title,
                    })}
                  >
                    <ul className={classList}>
                      {grandParent?.childItems.map((parent, pi) => (
                        <li key={'parent' + pi}>
                          <NavLink
                            {...parent}
                            hasArrow
                            onClick={() =>
                              parent?.childItems
                                ? setActiveItems(prevState => {
                                    return {
                                      ...prevState,
                                      parent: parent.title,
                                    }
                                  })
                                : undefined
                            }
                            className={classListItem}
                          />
                          {parent?.childItems && (
                            <div
                              className={clsx(classOverlay, {
                                'translate-x-0':
                                  activeItems.parent == parent.title,
                                'translate-x-full':
                                  activeItems.parent !== parent.title,
                              })}
                            >
                              <ul className={classList}>
                                {parent?.childItems.map((child, ci) => (
                                  <li key={'child' + ci}>
                                    <NavLink
                                      {...child}
                                      hasArrow
                                      className={classListItem}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default OffcanvasNav
