// Global packages and components
import { Link } from '@remix-run/react'
import { getLinkType } from '~/utils'
import { LinkInner } from '~/components'

// Types
import type { NavItem } from '~/types'

interface NavLinkProps extends NavItem {
  className?: string
  hasArrow?: boolean
  hasIcon?: boolean
  onClick?: () => void
  activeFilters?: { category: string; value: string }[]
  slug?: string
  min?: number
  max?: number
  start?: number
  end?: number
}

// Main export
const NavLink = ({
  title,
  titleBold,
  titleHighlight,
  linkType,
  linkSlug,
  linkReference,
  linkExternal,
  className,
  hasArrow,
  hasIcon,
  onClick,
}: NavLinkProps) => {
  const link = getLinkType({ linkType, linkReference, linkSlug, linkExternal })

  if (!link && !onClick) return null

  return onClick ? (
    <button
      onClick={onClick}
      className={'text-left ' + className}
    >
      <LinkInner
        icon={linkReference?.icon}
        title={title}
        titleBold={titleBold}
        titleHighlight={titleHighlight}
        hasArrow={hasArrow}
        hasIcon={hasIcon}
      />
    </button>
  ) : linkType == 'linkExternal' ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={'text-left ' + className}
    >
      <LinkInner
        icon={linkReference?.icon}
        title={title}
        titleBold={titleBold}
        titleHighlight={titleHighlight}
        hasArrow={hasArrow}
        hasIcon={hasIcon}
      />
    </a>
  ) : (
    <Link
      to={link}
      className={'text-left ' + className}
    >
      <LinkInner
        icon={linkReference?.icon}
        title={title}
        titleBold={titleBold}
        titleHighlight={titleHighlight}
        hasArrow={hasArrow}
        hasIcon={hasIcon}
      />
    </Link>
  )
}

export default NavLink
