const CustomIconFacebook = ({ className }: { className?: string } = {}) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.65605 5.94443V10.0816H1.7556V5.94443H0.176758V4.26692H1.7556V3.65658C1.7556 1.39066 2.70217 0.199219 4.70495 0.199219C5.31894 0.199219 5.47244 0.297896 5.80868 0.3783V2.03754C5.43224 1.97176 5.32625 1.93521 4.9352 1.93521C4.47105 1.93521 4.22253 2.06678 3.99594 2.32626C3.76934 2.58575 3.65605 3.03528 3.65605 3.67851V4.27057H5.80868L5.23123 5.94809H3.65605V5.94443Z"
      fill="#F3F4F6"
    />
  </svg>
)

export default CustomIconFacebook
