// Global packages and components
import { clsx } from 'clsx'
import { NavLink, Button, Slider } from '~/components'

// Types
import type { SanityNavItem } from '~/types'

interface Props {
  items: SanityNavItem[]
}

// Main export
const MegaNav = ({ items }: Props) => (
  <ul className="-mx-2 flex">
    {items.map((grandParent, gi) => (
      <li
        className="group/grandparent px-1 xl:px-2"
        key={'grandparent' + gi}
      >
        <NavLink
          {...grandParent}
          className={clsx(
            'inline-block border-b-2 border-transparent px-3 py-6',
            {
              'group-hover/grandparent:border-teal-green group-hover/grandparent:text-teal-green':
                !grandParent.titleHighlight,
              'group-hover/grandparent:border-red-600 group-hover/grandparent:text-red-600':
                grandParent.titleHighlight,
            }
          )}
        />
        {grandParent?.childItems && (
          <div className="absolute left-0 top-full z-10 hidden h-[30rem] w-full grid-cols-3 overflow-y-scroll rounded-b-lg bg-white shadow-md group-hover/grandparent:grid">
            <ul className="relative col-span-2 flex flex-col border-r border-neutral-100 py-3">
              {grandParent?.childItems.map((parent, pi) => (
                <li
                  className="group/parent w-1/2 px-3"
                  key={'parent' + pi}
                >
                  <NavLink
                    {...parent}
                    hasArrow
                    hasIcon
                    className="inline-block w-full rounded-lg p-3 group-hover/parent:bg-vibrant-green/20 group-hover/parent:font-semibold group-hover/parent:text-teal-green"
                  />
                  {parent?.childItems && (
                    <ul className="absolute right-0 top-0 hidden h-full w-1/2 flex-col border-r border-neutral-100 py-3 group-hover/parent:flex">
                      <div className="sticky top-3 h-[30rem] overflow-y-scroll">
                        {parent?.childItems.map((child, ci) => (
                          <li
                            className="group/child px-3"
                            key={'child' + ci}
                          >
                            <NavLink
                              {...child}
                              className="inline-block w-full rounded-lg p-2 group-hover/child:font-semibold group-hover/child:text-teal-green"
                            />
                          </li>
                        ))}
                      </div>
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <div className="sticky top-0 h-[30rem]">
              {grandParent?.featuredItems && (
                <div className="h-full w-full">
                  <Slider
                    dots
                    arrows={false}
                    type="fade"
                  >
                    {grandParent.featuredItems.map((item, i) => (
                      <div
                        className="relative h-full w-full"
                        key={i}
                      >
                        {item?.title && (
                          <div className="absolute left-0 top-0 z-[1] flex h-full w-full flex-col justify-end bg-black/20 px-5 py-10">
                            <h4 className="mb-5 block text-xl font-bold text-white">
                              {item.title}
                            </h4>
                            {item?.slug && (
                              <div>
                                <Button
                                  to={item?.slug}
                                  title="Learn more"
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {item?.image?.asset?.url && (
                          <img
                            src={item.image.asset.url}
                            alt={item.image?.alt}
                            className="absolute left-0 top-0 h-full w-full object-cover"
                          />
                        )}
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          </div>
        )}
      </li>
    ))}
  </ul>
)

export default MegaNav
