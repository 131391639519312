const CustomIconLinkedIn = ({ className }: { className?: string } = {}) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.28958 3.45117H0.224609V10.0362H2.28958V3.45117Z"
      fill="#F3F4F6"
    />
    <path
      d="M7.8764 3.30655C7.80027 3.29703 7.71939 3.29227 7.6385 3.28751C6.48231 3.23993 5.83047 3.92509 5.60208 4.22008C5.54023 4.30097 5.51168 4.34855 5.51168 4.34855V3.46832H3.53711V10.0534H5.51168H5.60208C5.60208 9.38251 5.60208 8.71639 5.60208 8.04551C5.60208 7.6839 5.60208 7.3223 5.60208 6.96069C5.60208 6.51344 5.56878 6.03764 5.7924 5.62845C5.98272 5.28587 6.32529 5.11458 6.71069 5.11458C7.85261 5.11458 7.8764 6.14707 7.8764 6.24223C7.8764 6.24699 7.8764 6.25175 7.8764 6.25175V10.0819H9.94137V5.78546C9.94137 4.31524 9.19437 3.44929 7.8764 3.30655Z"
      fill="#F3F4F6"
    />
    <path
      d="M1.25761 2.5992C1.9198 2.5992 2.45663 2.06239 2.45663 1.40019C2.45663 0.737989 1.9198 0.201172 1.25761 0.201172C0.595409 0.201172 0.0585938 0.737989 0.0585938 1.40019C0.0585938 2.06239 0.595409 2.5992 1.25761 2.5992Z"
      fill="#F3F4F6"
    />
  </svg>
)

export default CustomIconLinkedIn
