const CustomIconInstagram = ({ className }: { className?: string } = {}) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.81941 9.37489H2.18047C1.40125 9.37489 0.764648 8.73829 0.764648 7.95906V2.32012C0.764648 1.5409 1.40125 0.904297 2.18047 0.904297H7.81941C8.59864 0.904297 9.23524 1.5409 9.23524 2.32012V7.95906C9.23524 8.74177 8.60212 9.37489 7.81941 9.37489Z"
      fill="#F3F4F6"
    />
    <path
      d="M5.0023 7.31515C4.42136 7.31515 3.87521 7.08903 3.46472 6.67855C3.05424 6.26806 2.82812 5.72191 2.82812 5.14097C2.82812 4.56003 3.05424 4.01388 3.46472 3.6034C3.87521 3.19291 4.42136 2.9668 5.0023 2.9668C5.58324 2.9668 6.12939 3.19291 6.53988 3.6034C6.95036 4.01388 7.17648 4.56003 7.17648 5.14097C7.17648 5.72191 6.95036 6.26806 6.53988 6.67855C6.12591 7.08903 5.58324 7.31515 5.0023 7.31515ZM5.0023 3.42946C4.05958 3.42946 3.29079 4.19477 3.29079 5.14097C3.29079 6.08369 4.0561 6.85248 5.0023 6.85248C5.94502 6.85248 6.71381 6.08717 6.71381 5.14097C6.71033 4.19825 5.94502 3.42946 5.0023 3.42946Z"
      fill="#269D84"
    />
    <path
      d="M7.60007 2.89545C7.83062 2.89545 8.01752 2.70855 8.01752 2.478C8.01752 2.24745 7.83062 2.06055 7.60007 2.06055C7.36952 2.06055 7.18262 2.24745 7.18262 2.478C7.18262 2.70855 7.36952 2.89545 7.60007 2.89545Z"
      fill="#269D84"
    />
  </svg>
)

export default CustomIconInstagram
