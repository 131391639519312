// Global packages and functions
import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'
import { createGtmScript, createGtmNoScript, TrustpilotScript } from '~/utils'

// Types
import type { NonceType } from '~/types'

interface CustomScriptsProps {
  gtmId?: string
  nonce?: NonceType
}

// Main export
const useCustomScripts = ({ gtmId, nonce }: CustomScriptsProps) => {
  const location = useLocation()

  /*
   * ADD EXTERNAL SCRIPTS TO SITE (avoid hydration errors via useEffect)
   */
  useEffect(() => {
    /*
     * GTM
     */
    const gtmEl = document.getElementById('tracking-gtm')
    const gtmElInsert = document.getElementById('tracking-gtm-insert')

    // Check elements don't already exist and gtmId has been set
    if (!gtmEl && !gtmElInsert && gtmId) {
      const gtmScript = createGtmScript(gtmId, nonce)

      if (gtmScript) {
        document.head.appendChild(gtmScript)
      }
    }

    /*
     * GTM NO SCRIPT
     */
    const gtmElNoScript = document.getElementById('tracking-gtm-noscript')

    // Check elements don't already exist and gtmId has been set
    if (!gtmElNoScript && gtmId) {
      const gtmNoScript = createGtmNoScript(gtmId, nonce)

      if (gtmNoScript) {
        document.body.insertBefore(gtmNoScript, document.body.firstChild)
      }
    }

    /*
     * Trustpilot SCRIPT
     */
    const trustpilotElScript = document.getElementById('trustpilot-script')

    // Check elements don't already exist
    if (!trustpilotElScript) {
      const trustpilotScript = TrustpilotScript(nonce)

      if (trustpilotScript) {
        document.head.appendChild(trustpilotScript)
      }
    }

    /**
     * Remove elements on unmount
     */
    return () => {
      if (gtmEl) {
        gtmEl.remove()
      }

      if (gtmElInsert) {
        gtmElInsert.remove()
      }

      if (gtmElNoScript) {
        gtmElNoScript.remove()
      }

      if (trustpilotElScript) {
        trustpilotElScript.remove()
      }
    }
  }, [location, gtmId, nonce])

  return null
}

export default useCustomScripts
