// Global packages and components
import { useState, useEffect } from 'react'
import { Link, useLocation, useRouteLoaderData } from '@remix-run/react'
import { getCurrencySymbol, formatPrice } from '~/utils'
import {
  MegaNav,
  OffcanvasNav,
  AccountPopover,
  ProductSearch,
  SiteMessage,
  ContentEditor,
  Trustpilot,
} from '~/components'
import {
  ShoppingBagIcon,
  UserIcon,
  Bars3Icon,
} from '@heroicons/react/24/outline'
import { RootDataProps } from '~/types'

// Types
interface Props {
  basketTotal?: number
}

// Main export
const Header = ({ basketTotal }: Props = {}) => {
  const { site } = useRouteLoaderData('root') as RootDataProps
  const [offcanvasOpen, setOffcanvasOpen] = useState<boolean>(false)
  const location = useLocation()
  const primaryNav = site?.settings?.primaryNavigation
    ? site.navigations.find(e => e._id == site.settings.primaryNavigation._ref)
    : null
  const mobileNav = site?.settings?.mobileNavigation
    ? site.navigations.find(e => e._id == site.settings.mobileNavigation._ref)
    : null

  // Close nav when page path changes
  useEffect(() => {
    setOffcanvasOpen(false)
  }, [location.pathname])

  // On change of offcanvas elements
  useEffect(() => {
    // Remove scrollbar when demo open
    if (offcanvasOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [offcanvasOpen])

  return (
    <>
      {site?.global?.siteMessage && (
        <SiteMessage>
          <ContentEditor content={site.global.siteMessage} />
        </SiteMessage>
      )}
      <div className="bg-neutral-50">
        <div className="container pt-5">
          <div className="mb-5 flex items-center justify-between gap-5">
            <div>
              {site?.global?.logo?.asset?.url && (
                <Link to="/">
                  <img
                    className="max-w-40"
                    src={site.global.logo.asset.url}
                    alt={site.global.logo?.alt}
                  />
                </Link>
              )}
            </div>
            <div className="hidden md:flex md:flex-1 md:justify-center">
              <div className="w-full max-w-[30rem]">
                <ProductSearch />
              </div>
            </div>
            <div className="hidden lg:block">
              <Trustpilot templateId="mini" />
            </div>
            <div className="hidden items-center gap-3 lg:flex">
              <AccountPopover />
              <Link
                to="/basket"
                className="flex items-center rounded-3xl bg-gray-100 px-4 py-2.5"
              >
                <ShoppingBagIcon className="mr-2 size-5" />
                <span>
                  {getCurrencySymbol('GBP')}
                  {basketTotal ? formatPrice(basketTotal) : '0.00'}
                </span>
              </Link>
            </div>
            <div className="flex items-center gap-2 lg:hidden">
              <Link
                to="/account"
                className="flex size-10 items-center justify-center rounded-full bg-gray-100"
              >
                <UserIcon className="size-5" />
              </Link>
              <Link
                to="/basket"
                className="flex size-10 items-center justify-center rounded-full bg-gray-100"
              >
                <ShoppingBagIcon className="size-5" />
              </Link>
              <button
                className="flex size-10 items-center justify-center rounded-full bg-gray-100"
                onClick={() => setOffcanvasOpen(true)}
              >
                <Bars3Icon className="size-6" />
              </button>
            </div>
          </div>
          <div className="block md:hidden">
            <ProductSearch />
          </div>
          <div className="group/wrapper relative hidden items-center justify-between rounded-2xl bg-gray-100 px-6 group-hover/grandparent:rounded-b-none lg:flex">
            {primaryNav?.items && <MegaNav items={primaryNav.items} />}
          </div>
        </div>
      </div>
      {mobileNav?.items && (
        <OffcanvasNav
          open={offcanvasOpen}
          items={mobileNav.items}
          setOpen={setOffcanvasOpen}
        />
      )}
    </>
  )
}

export default Header
