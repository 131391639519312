// Global packages and components
import { useRouteLoaderData } from '@remix-run/react'
import {
  Button,
  ContentEditor,
  NavLink,
  SocialChannels,
  Heading,
} from '~/components'
import { RootDataProps } from '~/types'

// Main export
const Footer = () => {
  const { site } = useRouteLoaderData('root') as RootDataProps

  const colOneNav = site?.settings?.footerColOneNavigation
    ? site.navigations.find(
        e => e._id == site.settings.footerColOneNavigation._ref
      )
    : null
  const colTwoNav = site?.settings?.footerColTwoNavigation
    ? site.navigations.find(
        e => e._id == site.settings.footerColTwoNavigation._ref
      )
    : null
  const colThreeNav = site?.settings?.footerColThreeNavigation
    ? site.navigations.find(
        e => e._id == site.settings.footerColThreeNavigation._ref
      )
    : null
  const legalNav = site?.settings?.legalNavigation
    ? site.navigations.find(e => e._id == site.settings.legalNavigation._ref)
    : null

  return (
    <div className="bg-emerald-green py-10 text-white">
      <div className="container">
        <div className="mb-6 grid gap-6 md:mb-10 md:grid-cols-2 md:gap-10 lg:grid-cols-4">
          <div>
            {site?.global?.contentMarketplace && (
              <div className="mb-10">
                <div className="mb-6">
                  <ContentEditor content={site.global.contentMarketplace} />
                </div>
                {site?.global?.marketplaceLink && (
                  <Button
                    to={site.global.marketplaceLink}
                    title="Learn more"
                    hasArrow
                  />
                )}
              </div>
            )}
            {site?.global?.contentCareers && (
              <div className="mb-10">
                <ContentEditor content={site.global.contentCareers} />
              </div>
            )}
            {site?.global?.socialChannels && (
              <>
                <Heading
                  tag="h4"
                  size="h7"
                  font={false}
                >
                  Our Socials
                </Heading>
                <SocialChannels items={site.global.socialChannels} />
              </>
            )}
          </div>
          <div>
            {site?.global?.columnOneTitle && (
              <Heading
                tag="h4"
                size="h7"
                font={false}
              >
                {site.global.columnOneTitle}
              </Heading>
            )}
            {colOneNav?.items && (
              <ul>
                {colOneNav.items.map((e, i) => (
                  <li
                    className="mb-3"
                    key={i}
                  >
                    <NavLink
                      {...e}
                      className="text-sm"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            {site?.global?.columnTwoTitle && (
              <Heading
                tag="h4"
                size="h7"
                font={false}
              >
                {site.global.columnTwoTitle}
              </Heading>
            )}
            {colTwoNav?.items && (
              <ul>
                {colTwoNav.items.map((e, i) => (
                  <li
                    className="mb-3"
                    key={i}
                  >
                    <NavLink
                      {...e}
                      className="text-sm"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            {site?.global?.columnThreeTitle && (
              <Heading
                tag="h4"
                size="h7"
                font={false}
              >
                {site.global.columnThreeTitle}
              </Heading>
            )}
            {colThreeNav?.items && (
              <ul>
                {colThreeNav.items.map((e, i) => (
                  <li
                    className="mb-3"
                    key={i}
                  >
                    <NavLink
                      {...e}
                      className="text-sm"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="mb-6 md:mb-10 md:flex md:justify-between">
          {legalNav?.items && (
            <ul className="mb-6 md:mb-0 md:flex md:gap-5">
              {legalNav.items.map((e, i) => (
                <li
                  className="mb-3"
                  key={i}
                >
                  <NavLink
                    {...e}
                    className="text-sm text-gray-400"
                  />
                </li>
              ))}
            </ul>
          )}
          <div>
            {site?.global?.cardImages?.images && (
              <ul className="-mx-2 flex flex-wrap">
                {site.global.cardImages.images.map((e, i) => (
                  <li
                    className="px-2"
                    key={i}
                  >
                    {e.asset.url && (
                      <img
                        src={e.asset.url}
                        alt={e?.alt}
                        className="w-12"
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="lg:flex lg:justify-between">
          {site?.global?.copyright && (
            <p className="text-sm text-gray-400">{site.global.copyright}</p>
          )}
          {site?.global?.companyNo && (
            <p className="text-sm text-gray-400 lg:text-center">
              {site.global.companyNo}
            </p>
          )}
          {site?.global?.address && (
            <p className="text-sm text-gray-400 lg:text-right">
              {site.global.address}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer
