// Global packages and components
import {
  CustomIconFacebook,
  CustomIconInstagram,
  CustomIconLinkedIn,
  CustomIconYoutube,
  CustomIconX,
} from '~/components'

// Types
import type { SocialChannelOptions } from '~/types'

interface SocialChannelsProps {
  items: Array<{ icon: SocialChannelOptions; title?: string; link: string }>
}

// Icon element
const SocialIcon = ({ name }: { name: SocialChannelOptions }) => {
  const classes = 'size-4'

  return name == 'youtube' ? (
    <CustomIconYoutube className={classes} />
  ) : name == 'linkedin' ? (
    <CustomIconLinkedIn className={classes} />
  ) : name == 'x' ? (
    <CustomIconX className={classes} />
  ) : name == 'meta' ? (
    <CustomIconFacebook className={classes} />
  ) : name == 'instagram' ? (
    <CustomIconInstagram className={classes} />
  ) : null
}

// Main export
const SocialChannels = ({ items }: SocialChannelsProps) => (
  <ul className="-mx-2 mb-5 flex items-center">
    {items.map((e, i) => (
      <li
        key={i}
        className="px-2"
      >
        {e?.link ? (
          <a
            href={e.link}
            target="_blank"
            rel="noreferrer"
            className="flex size-8 items-center justify-center rounded-full bg-teal-green text-white hover:bg-teal-green/70"
          >
            <SocialIcon name={e.icon} />
          </a>
        ) : (
          <div className="flex size-8 items-center justify-center rounded-full bg-teal-green text-white">
            <SocialIcon name={e.icon} />
          </div>
        )}
      </li>
    ))}
  </ul>
)

export default SocialChannels
