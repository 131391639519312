const CustomIconYoutube = ({ className }: { className?: string } = {}) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4477 9.23426H2.97639C1.8429 9.23426 0.929688 8.23182 0.929688 6.99883V2.99911C0.929688 1.7611 1.84749 0.763672 2.97639 0.763672H11.4477C12.5812 0.763672 13.4944 1.76611 13.4944 2.99911V6.99883C13.499 8.23684 12.5812 9.23426 11.4477 9.23426Z"
      fill="#F3F4F6"
    />
    <path
      d="M9.43377 4.93585L5.87012 2.88086V6.99085L9.43377 4.93585Z"
      fill="#269D84"
    />
  </svg>
)

export default CustomIconYoutube
