// Global packages and components
import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { useRouteLoaderData } from '@remix-run/react'
import { RootDataProps } from '~/types'

// Main export
const Notification = () => {
  const { notification } = useRouteLoaderData('root') as RootDataProps
  const [show, setShow] = useState<boolean>(false)

  // When global notification changes
  useEffect(() => {
    if (notification) {
      setShow(true)

      const timer = setTimeout(() => {
        setShow(false)
      }, 2500)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [notification])

  return (
    <div
      className={clsx(
        'fixed right-0 top-10 z-10 rounded-l-md p-4 transition-transform duration-1000',
        {
          'translate-x-full': !show,
          'translate-x-0': show,
          'bg-rose-500': notification?.type == 'error',
          'bg-green-500': notification?.type == 'success',
        }
      )}
    >
      {notification?.text}
    </div>
  )
}

export default Notification
