// React
import { useContext, type ReactNode } from 'react'

// Packages and functions
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { getSite } from '~/sanity'
import {
  getSession,
  commitSession,
  fetchCart,
  fetchUser,
  getAuthToken,
} from '~/.server'
import {
  Notification,
  Header,
  Footer,
  Button,
  ErrorResponse,
} from '~/components'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { redirect } from '@remix-run/node'
import { NonceContext } from './utils'
import { useCustomScripts, useSnowplow } from '~/hooks'

// Styles
import styles from '~/tailwind.css?url'

// Type declarations
import type { LinksFunction, LoaderFunction } from '@remix-run/node'
import type { RootDataProps } from '~/types'

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: 'https://use.typekit.net/ubt6xee.css' },
]

// Loader data
export const loader: LoaderFunction = async ({ request }) => {
  // Global env variables
  const { pathname, search } = new URL(request.url)
  const env = process.env

  // Redirect for trailing slash URLs
  if (pathname.endsWith('/') && pathname !== '/') {
    return redirect(`${pathname.slice(0, -1)}${search}`, 301)
  }

  // Session & cookie data
  const cookieHeader = request.headers.get('Cookie')
  const session = await getSession(cookieHeader)

  // User entered address
  const address = session.get('address') ?? null

  // Lunar user data
  const lunarToken = await getAuthToken(request)
  const lunarUser = lunarToken ? await fetchUser(request) : null

  // Fetch global data
  const site = await getSite({
    dataset: env.ENVIRONMENT,
    projectId: env.SANITY_PROJECT,
  })

  // Session flash data
  const notification = session.has('notification')
    ? session.get('notification')
    : null

  // Cart data for our total in header (reduced data)
  const cart = await fetchCart(request, true)

  return Response.json(
    {
      site,
      cart: cart,
      user: lunarUser,
      userAddress: address ? JSON.parse(address) : null,
      notification: notification,
      env: {
        ENVIRONMENT: env?.ENVIRONMENT,
        SANITY_PROJECT: env?.SANITY_PROJECT,
        SITE_URL: env?.SITE_URL,
        BRENDA_API_URL: env?.BRENDA_API_URL,
        GOOGLE_MAPS_API_KEY: env?.GOOGLE_MAPS_API_KEY,
        GETADDRESS_IO_API_KEY: env?.GETADDRESS_IO_API_KEY,
        SNOWPLOW_URL: env?.SNOWPLOW_URL,
        SNOWPLOW_APPID: env?.SNOWPLOW_APPID,
        VITE_STRIPE_PUBLIC_KEY: env?.VITE_STRIPE_PUBLIC_KEY,
      },
    },
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    }
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    return (
      <main>
        <div className="container mb-8">
          <ErrorResponse message={error.status + ' ' + error.statusText}>
            <p>{error.data}</p>
          </ErrorResponse>
          <div className="text-center">
            <Button
              title="Back to home"
              to="/"
            />
          </div>
        </div>
      </main>
    )
  } else if (error instanceof Error) {
    return (
      <main>
        <div className="container mb-8">
          <ErrorResponse message="Error">
            <p>{error.message}</p>
            <p>The stack trace is:</p>
            <pre className="whitespace-normal rounded-md bg-neutral-200 p-2 text-sm">
              {error.stack}
            </pre>
          </ErrorResponse>
          <div className="text-center">
            <Button
              title="Back to home"
              to="/"
            />
          </div>
        </div>
      </main>
    )
  } else {
    return (
      <main>
        <div className="container">
          <ErrorResponse message="Unknown Error">
            <p>
              If this problem persists, please contact{' '}
              <a
                href="mailto:onlinesupport@hss.com"
                className="underline"
              >
                onlinesupport@hss.com
              </a>
            </p>
          </ErrorResponse>
        </div>
      </main>
    )
  }
}

// Main export
export function Layout({ children }: { children: ReactNode }) {
  const { site, cart, env } = useRouteLoaderData('root') as RootDataProps
  const nonce = useContext(NonceContext)

  // Init snowplow tracker
  useSnowplow({
    initUrl: env?.SNOWPLOW_URL,
    initAppID: env?.SNOWPLOW_APPID,
  })

  // Init custom scripts
  useCustomScripts({
    gtmId: site?.settings?.gtmId,
    nonce: nonce,
  })

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <Links />
        <Meta />
      </head>
      <body className="bg-neutral-50 text-gray-900">
        <Header basketTotal={cart?.data?.attributes?.prices?.total} />
        {children}
        <Footer />
        <Notification />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  return <Outlet />
}

export default withSentry(App)
