const CustomIconX = ({ className }: { className?: string } = {}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 12 10"
    fill="none"
  >
    <path
      d="M9.17217 0H10.8644L7.16781 4.23571L11.517 10.0005H8.11206L5.44326 6.5049L2.39298 10.0005H0.699173L4.65271 5.46843L0.482422 0.000788176H3.97408L6.38278 3.1953L9.17217 0ZM8.57709 8.98532H9.51503L3.46176 0.962375H2.45604L8.57709 8.98532Z"
      fill="#F3F3F3"
    />
  </svg>
)

export default CustomIconX
